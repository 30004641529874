import { Context, createContext, FC, ReactNode, useEffect, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { useApplicationsCountQuery } from '../../generated/hooks';
import { CommunityCommunityApplicationStatusChoices } from '../../generated/types';

interface IMembersContext {
  newMembersCount: number;
}

const MembersContext: Context<IMembersContext> = createContext({
  newMembersCount: 0
});

interface IMembersProvider {
  children: ReactNode;
}

export const MembersProvider: FC<IMembersProvider> = ({ children }) => {
  const pathname = usePathname();

  const [response, refetchCount] = useApplicationsCountQuery({
    variables: {
      status: [CommunityCommunityApplicationStatusChoices.New]
    },
  });

  useEffect(() => {
    refetchCount();
  }, [pathname, refetchCount]);

  const value = useMemo(() => ({
    newMembersCount: response.data?.applications?.count || 0
  }), [response.data?.applications?.count]);

  return (
    <MembersContext.Provider value={value}>
      {children}
    </MembersContext.Provider>
  );
};

export default MembersContext;
