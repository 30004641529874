import { createContext, FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { HelpModal } from '../(authenticated)/(owner)/landing/components/Modal/HelpModal';
import { Dialog, DialogContent } from 'components/ui/dialog';

interface IHelpModalContext {
  isOpen: boolean;
  toggle: () => void;
}

const defaultValues: IHelpModalContext = {
  isOpen: false,
  toggle: () => {},
};

const HelpModalContext = createContext(defaultValues);

export const HelpModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const toggle = useCallback(() => setOpen(prev => !prev), []);

  const values = useMemo(() => ({ isOpen, toggle }), [isOpen, toggle]);

  return (
    <HelpModalContext.Provider value={values}>
      {children}
      {isOpen && (
        <Dialog open onOpenChange={toggle}>
          <DialogContent hideCloseIcon className="p-0 min-w-[600px] md:min-w-0">
            <HelpModal />
          </DialogContent>
        </Dialog>
      )}
    </HelpModalContext.Provider>
  );
};

export default HelpModalContext;
