import Hotjar from '@hotjar/browser';

export const initHotjar = () => {
  if (typeof window === 'undefined') {
    return;
  }
  const siteID = process.env.HOTJAR_SITE_ID;
  if (!siteID) {
    console.log('Hotjar siteID not set');
    return;
  }
  const hotjarVersion = 6;
  Hotjar.init(parseInt(siteID), hotjarVersion);
};