import { createContext, ReactNode, useEffect } from 'react';
import { usePageTracking } from './events';

const TrackingContext = createContext(undefined);
type Props = {
  children: ReactNode;
};

export function TrackingContextProvider({ children }: Props) {
  const { trackPage } = usePageTracking();

  useEffect(() => {
    trackPage();
  }, [trackPage]);

  // useEffect(() => {
  //   // TODO: does not work with the new version of next.js
  //   const handler = (url: string) => trackPage(url);
  //   router.events.on('routeChangeComplete', handler);
  //
  //   return () => {
  //     router.events.off('routeChangeComplete', handler);
  //   };
  // }, [trackPage, router.events]);

  return (
    <TrackingContext.Provider value={undefined}>
      {children}
    </TrackingContext.Provider>
  );
}
