import React from 'react';
import Image from 'next/image';
import alexImg from './alex.png';
import Link from 'next/link';
import { IconPhone } from 'components/icons/phone';
import { EmailIcon } from 'components/icons/emailIcon';
import { IconTwitter } from 'components/icons/twitter';


export const HelpModal: React.FC = () => {
  return <>
    <div className={'flex flex-col items-center p-10 xs:p-5'}>
      <h1 className={'text-3xl font-medium'}>Help Center</h1>
      <div className={'grid grid-cols-2 mt-5 sm:grid-cols-1 sm:gap-5'}>
        <div className={'bg-purple-blue bg-opacity-5 rounded-2xl px-5 pt-8'}>
          <div
            className={'rounded-2xl border-white border-4 border-double bg-purple-blue pt-2 px-2 pb-10 text-white text-center flex flex-col gap-2'}>
            <h3 className={'text-sm font-medium'}>Hi this is Oleksiy</h3>
            <span className={'text-xs'}>Co-Founder of KYG</span>
            <hr className={'m-auto w-[30%]'} />
            <span className={'text-sm font-medium'}>&ldquo;If you have any questions you can contact me&rdquo;</span>
          </div>
          <div className={'w-full relative -top-10 flex justify-center'}>
            <Image src={alexImg} width={92} height={92} alt={'Alex'} className={''} />
          </div>
        </div>
        <div className={''}>
          <div className={'flex flex-col justify-center h-full gap-4 pl-10 sm:pl-0 sm:text-center'}>
            <h3 className={'text-lg font-bold'}>Contact Information</h3>
            <div className={'flex justify-start items-center sm:justify-center'}>
              <span className={'p-1 rounded-full border border-gray-light'}>
                <IconPhone classNames={'text-purple-blue'}  width={30} height={30}/>
              </span>
              <Link href={'tel:424-527-6359'} className={'text-md ml-2 hover:underline'}>(424) 527-6359</Link>
            </div>
            <div className={'flex justify-start items-center sm:justify-center'}>
              <span className={'p-2 rounded-full border border-gray-light'}>
                <EmailIcon classNames={'text-purple-blue'} width={22} height={22} />
              </span>
              <Link href={'mailto:www@gokyg.com'} className={'text-md ml-2 hover:underline'}>www@gokyg.com</Link>
            </div>
            <div className={'flex justify-start items-center sm:justify-center'}>
              <span className={'p-2 rounded-full border border-gray-light'}>
                <IconTwitter classNames={'text-purple-blue'} />
              </span>
              <Link href={'https://twitter.com/mind_game7'} target={'_blank'} className={'text-md ml-2 hover:underline'}>@mind_game7</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};
